import {Component, inject, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StorageService} from "../core/storage/storage.service";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekURL} from "../shared/boek/boek-url";

@Component({
  selector: "app-pboek-aanvraag",
  standalone: true,
  imports: [CommonModule],
  template: `
    @for (a of element.aanvraag; track $index) {
      {{a.nummer}}
      @for (f of a.formulier; track $index) {
        @if ($first) {
          <span>(</span>
        }
        @if (getFormulierDocument(f) | async; as document) {
          <a [href]="document" target="_blank">{{f.nummer}}</a>
        } @else {
          {{f.nummer}}
        }
        @if ($last) {
          <span>)</span>
        } @else {
          <span>,</span>
        }
      }
      <br/>
    }
  `
})
export class PboekAanvraagComponent {
  @Input() element: any;
  @Input() locatie: BoekLocatie;
  private readonly storage = inject(StorageService);
  private readonly formulierDocumentCache = new Map<number, Promise<string>>();

  getFormulierDocument(formulier: any): Promise<string> {
    const nummer: number = formulier.nummer;
    let formulierDocument$: Promise<string> | undefined = this.formulierDocumentCache.get(nummer);
    if (!formulierDocument$) {
      formulierDocument$ = BoekURL.getFormulierDocument(this.storage, this.locatie, nummer);
      this.formulierDocumentCache.set(nummer, formulierDocument$);
    }
    return formulierDocument$;
  }
}
