import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Router} from "@angular/router";
import {FormsModule} from "@angular/forms";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekType} from "../shared/boek/boek-type";
import {BoekVersie} from "../shared/boek/boek-versie";

@Component({
  selector: "app-locatie",
  standalone: true,
  imports: [CommonModule, FormsModule],
  template: `
    <select [ngModel]="locatie" (ngModelChange)="navigateLocatie($event)" [disabled]="!locatie">
      @for (loc of locaties; track loc) {
        <option [value]="loc">{{loc}}</option>
      }
    </select>
  `
})
export class LocatieComponent {
  readonly locaties: BoekLocatie[];
  @Input() type: BoekType;
  @Input() versie: BoekVersie;
  @Input() locatie: BoekLocatie | null;
  @Input() code: number | null;

  constructor(private router: Router) {
    this.locaties = ["GHB", "DIEST"];
    this.locaties.sort();
  }

  navigateLocatie(locatie: BoekLocatie): void {
    const commands: any[] = [this.type, this.versie, locatie];
    if (this.code) {
      commands.push(this.code);
    }
    this.router.navigate(commands);
  }
}
