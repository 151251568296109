import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-pboek-diagnose-regel",
  standalone: true,
  imports: [CommonModule],
  template: `
    @for (r of element.diagnoseRegel; track $index) {
      {{r.omschrijving}}
      @if (!$last) {
        <hr/>
      }
    }
  `
})
export class PboekDiagnoseRegelComponent {
  @Input() element: any;
}
