import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekVersie} from "../shared/boek/boek-versie";
import {PboekUitvoerfrequentieComponent} from "./pboek-uitvoerfrequentie.component";
import {PboekUitvoeringstijdComponent} from "./pboek-uitvoeringstijd.component";
import {PboekAanvraagComponent} from "./pboek-aanvraag.component";
import {PboekAfnameMateriaalComponent} from "./pboek-afname-materiaal.component";
import {PboekCollectiemateriaalComponent} from "./pboek-collectiemateriaal.component";
import {PboekNietZIVPrijsComponent} from "./pboek-niet-ziv-prijs.component";
import {PboekVerantwoordelijkeComponent} from "./pboek-verantwoordelijke.component";
import {PboekUitvoerendLaboComponent} from "./pboek-uitvoerend-labo.component";
import {PboekDiagnoseRegelComponent} from "./pboek-diagnose-regel.component";
import {PboekAfnameInstructieComponent} from "./pboek-afname-instructie.component";
import {PboekMeesleurComponent} from "./pboek-meesleur.component";
import {PboekRIZIVComponent} from "./pboek-riziv.component";
import {PboekSynoniemComponent} from "./pboek-synoniem.component";
import {PboekCustomComponent} from "./pboek-custom.component";

@Component({
  selector: "app-pboek-data",
  standalone: true,
  imports: [CommonModule, PboekUitvoerfrequentieComponent, PboekUitvoeringstijdComponent, PboekAanvraagComponent,
    PboekAfnameMateriaalComponent, PboekCollectiemateriaalComponent, PboekNietZIVPrijsComponent,
    PboekVerantwoordelijkeComponent, PboekUitvoerendLaboComponent, PboekDiagnoseRegelComponent,
    PboekAfnameInstructieComponent, PboekMeesleurComponent, PboekRIZIVComponent, PboekSynoniemComponent,
    PboekCustomComponent],
  template: `
    <table cellpadding="4" cellspacing="1" class="border" border="0" width="100%">
      <tr>
        <td colspan="2" class="header">{{entiteit.omschrijving}}</td>
      </tr>
      @if (entiteit.plaats) {
        <tr>
          <td colspan="2" [class]="'cell ' + entiteit.plaats" style="vertical-align:top;">
            Deze entiteit is enkel <b>{{entiteit.plaats}}</b> aanvraagbaar
          </td>
        </tr>
      }
      @for (element of entiteit.element; track $index) {
        <tr>
          @if (!element.verbergTitel) {
            <td class="title" style="text-align:right; vertical-align:top;">
              {{element.titel}}
              @if (element.type === "collectiemateriaal") {
                <br/><a href="https://wiki/x/L4JVDw" target="_blank" class="extra">pediatrische alternatieven</a>
              }
            </td>
          }
          <td [attr.colspan]="element.verbergTitel ? 2 : 1" class="cell" style="vertical-align:top;">
            @switch (element.type) {
              @case ("uitvoerfrequentie") {
                <app-pboek-uitvoerfrequentie [element]="element"></app-pboek-uitvoerfrequentie>
              }
              @case ("uitvoeringstijd") {
                <app-pboek-uitvoeringstijd [element]="element"></app-pboek-uitvoeringstijd>
              }
              @case ("aanvraag") {
                <app-pboek-aanvraag [element]="element" [locatie]="locatie"></app-pboek-aanvraag>
              }
              @case ("afnameMateriaal") {
                <app-pboek-afname-materiaal [element]="element"></app-pboek-afname-materiaal>
              }
              @case ("collectiemateriaal") {
                <app-pboek-collectiemateriaal [element]="element" [locatie]="locatie"></app-pboek-collectiemateriaal>
              }
              @case ("nietZIVPrijs") {
                <app-pboek-niet-ziv-prijs [element]="element"></app-pboek-niet-ziv-prijs>
              }
              @case ("verantwoordelijke") {
                <app-pboek-verantwoordelijke [element]="element" [versie]="versie" [feedback]="feedback">
                </app-pboek-verantwoordelijke>
              }
              @case ("uitvoerendLabo") {
                <app-pboek-uitvoerend-labo [element]="element"></app-pboek-uitvoerend-labo>
              }
              @case ("diagnoseRegel") {
                <app-pboek-diagnose-regel [element]="element"></app-pboek-diagnose-regel>
              }
              @case ("afnameInstructie") {
                <app-pboek-afname-instructie [element]="element"></app-pboek-afname-instructie>
              }
              @case ("meesleur") {
                <app-pboek-meesleur [element]="element" [versie]="versie" [locatie]="locatie"></app-pboek-meesleur>
              }
              @case ("RIZIV") {
                <app-pboek-riziv [element]="element"></app-pboek-riziv>
              }
              @case ("synoniem") {
                <app-pboek-synoniem [element]="element"></app-pboek-synoniem>
              }
              @case ("custom") {
                <app-pboek-custom [element]="element"></app-pboek-custom>
              }
              @default {
                <div class="error">ERROR: inhoud kan niet worden weergegeven</div>
              }
            }
          </td>
        </tr>
      }
    </table>
  `
})
export class PboekDataComponent {
  @Input() entiteit: any;
  @Input() versie: BoekVersie;
  @Input() locatie: BoekLocatie;
  @Input() feedback?: string;
}
