import {Component} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute, RouterModule} from "@angular/router";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {OverzichtPboekRapport} from "./overzicht-pboek-rapport";
import {RapportRij} from "./rapport-rij";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {ExportTableComponent} from "../shared/export/export-table.component";

@Component({
  selector: "app-overzicht-recipient",
  standalone: true,
  imports: [CommonModule, RouterModule, PaginaOphalenComponent, ExportTableComponent],
  template: `
    <app-pagina-ophalen>
      @if (tabel$ | async; as tabel) {
        <app-export-table [table]="exportTable" filename="recipienten"></app-export-table>
        <table #exportTable class="border">
          <caption>Recipiënt</caption>
          <tr>
            <th class="header">nummer</th>
            <th class="cell">testnaam</th>
            <th class="cell">afnamemateriaal</th>
            <th class="cell">collectiemateriaal</th>
          </tr>
          @for (rij of tabel; track $index) {
            <tr>
              <td class="header code-field">
                <a [routerLink]="['/', type, versie, locatie, rij.entiteitCode]">{{rij.entiteitCode}}</a>
              </td>
              <td class="cell">{{rij.entiteitOmschrijving}}</td>
              <td class="cell">
                @for (afnameMateriaal of rij.afnameMaterialen; track afnameMateriaal) {
                  {{afnameMateriaal}}
                  @if (!$last) {
                    <br/>
                  }
                }
              </td>
              <td class="cell">
                @for (collectiemateriaal of rij.collectiematerialen; track collectiemateriaal) {
                  {{collectiemateriaal}}
                  @if (!$last) {
                    <br/>
                  }
                }
              </td>
            </tr>
          }
        </table>
      }
    </app-pagina-ophalen>
  `,
  styleUrl: "./overzicht.css"
})
export class OverzichtRecipientComponent extends OverzichtPboekRapport {
  constructor(logger: LoggerService, pagina: PaginaService, db: DatabaseService, route: ActivatedRoute) {
    super(logger, pagina, db, route, entiteit => {
      return new RapportRij(entiteit.code,
        entiteit.omschrijving,
        [],
        this.getAfnameMaterialen(entiteit),
        this.getCollectiematerialen(entiteit),
        [],
        new Map(),
        [],
        null,
        [],
        [],
        [],
        [],
        [],
        []);
    });
  }
}
