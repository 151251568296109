import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-pboek-afname-materiaal",
  standalone: true,
  imports: [CommonModule],
  template: `
    @for (m of element.afnameMateriaal; track $index) {
      <div [class]="m.plaats">
        @if (m.naam) {
          <b>{{m.naam}}</b><br/>
        }
        @for (a of m.afnameMateriaalAlternatief; track $index) {
          {{a.naam}}
          @if (a.voorkeur && m.afnameMateriaalAlternatief.length > 1) {
            (voorkeur)
          }
          @if (!$last) {
            <br/>
          }
        }
        @if (!$last) {
          <hr/>
        }
      </div>
    }
  `
})
export class PboekAfnameMateriaalComponent {
  @Input() element: any;
}
