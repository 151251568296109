import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-pboek-afname-instructie",
  standalone: true,
  imports: [CommonModule],
  template: `
    @for (ai of element.afnameInstructie; track $index) {
      <div [class]="ai.plaats">
        <b>{{ai.titel}}</b><br/>
        @for (i of ai.instructie; track $index) {
          @for (c of i.content; track $index) {
            @if (c.href) {
              <a [href]="c.href" target="_blank">{{c.href}}</a>
            } @else {
              <span class="preserve-text">{{c}}</span>
            }
          }
          <br/>
        }
        @if (!$last) {
          <hr/>
        }
      </div>
    }
  `
})
export class PboekAfnameInstructieComponent {
  @Input() element: any;
}
