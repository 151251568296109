import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute, ParamMap, RouterModule} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {Overzicht} from "./overzicht";
import {PaginaOphalenStatus} from "../shared/pagina/pagina-ophalen-status";
import {BoekType} from "../shared/boek/boek-type";
import {BoekVersie} from "../shared/boek/boek-versie";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {ExportTableComponent} from "../shared/export/export-table.component";

@Component({
  selector: "app-overzicht-eboek-rapport",
  standalone: true,
  imports: [CommonModule, RouterModule, PaginaOphalenComponent, ExportTableComponent],
  template: `
    <app-pagina-ophalen>
      @if (entiteiten$ | async; as entiteiten) {
        <app-export-table [table]="table" filename="rapport"></app-export-table>
        <table #table class="border">
          <caption>Rapport</caption>
          <tr>
            <th class="cell">analysenr.</th>
            <th class="cell">analyse</th>
            <th class="cell">voorkeur recipiënt</th>
            <th class="cell">recipiënt</th>
            <th class="cell">aanvraagcode(s)</th>
            <th class="cell">rapporteerbare code(s)</th>
            <th class="cell">bijkomende omschrijving</th>
          </tr>
          @for (entiteit of entiteiten; track $index) {
            @for (staal of entiteit.staal; track $index) {
              <tr>
                @if ($first) {
                  <td [attr.rowspan]="entiteit.staal.length" class="cell code-field">{{getAanvraagnummer(entiteit)}}</td>
                  <td [attr.rowspan]="entiteit.staal.length" class="cell">
                    <a [routerLink]="['/', type, versie, entiteit.code]">{{entiteit.omschrijving}}</a>
                  </td>
                }
                <td class="cell">{{getRecipienten(staal, true)}}</td>
                <td class="cell">{{getRecipienten(staal, false)}}</td>
                <td class="cell">{{getCodes(staal)}}</td>
                <td class="cell">
                  @if (staal.toegelaten) {
                    {{getCodes(staal.toegelaten)}}
                  }
                </td>
                <td class="cell">
                  @if (staal.omschrijving) {
                    {{staal.omschrijving}}
                  }
                </td>
              </tr>
            }
          }
        </table>
      }
    </app-pagina-ophalen>
  `,
  styleUrl: "./overzicht.css"
})
export class OverzichtEboekRapportComponent implements Overzicht, OnInit {
  readonly type: BoekType = "eboek";
  readonly versie: BoekVersie = "internet";
  readonly locatie = null;

  entiteiten$: Observable<any[]>;

  constructor(private readonly logger: LoggerService,
              private readonly pagina: PaginaService,
              private readonly db: DatabaseService,
              private readonly route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.entiteiten$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap): Observable<any[]> => {
        this.pagina.setStatus(PaginaOphalenStatus.BUSY);
        return this.db.getEntiteiten(this.type, this.versie, this.locatie).pipe(
          map((entiteiten: any[]) => entiteiten.sort((e1: any, e2: any) => e1.omschrijving.localeCompare(e2.omschrijving)))
        );
      }),
      tap(() => this.pagina.setStatus(PaginaOphalenStatus.SUCCEEDED)),
      catchError(err => {
        this.logger.error(err);
        this.pagina.setStatus(PaginaOphalenStatus.FAILED);
        return of([]);
      })
    );
  }

  getCodes(staalOfToegelaten: any): string | null {
    if (staalOfToegelaten.aanvraagTest) {
      if (Array.isArray(staalOfToegelaten.aanvraagTest)) {
        const aanvraagTesten: any[] | null = staalOfToegelaten?.aanvraagTest;
        return (aanvraagTesten && aanvraagTesten.length) ? aanvraagTesten.map(t => t.code + ' (' + t.systeem + ')').join(', ') + ')' : null;
      } else {
        const aanvraagTest = staalOfToegelaten.aanvraagTest;
        return aanvraagTest.code + ' (' + aanvraagTest.systeem +  ')';
      }
    } else if (staalOfToegelaten.and?.element) {
      const aanvraagTesten: any[] | null = staalOfToegelaten.and?.element;
      return (aanvraagTesten && aanvraagTesten.length) ? 'en (' + aanvraagTesten.map(t => t.code + ' (' + t.systeem + ')').join(', ') + ')' : null;
    } else if (staalOfToegelaten.or?.element) {
      const aanvraagTesten: any[] | null = staalOfToegelaten.or?.element;
      return (aanvraagTesten && aanvraagTesten.length) ? 'of (' + aanvraagTesten.map(t => t.code + ' (' + t.systeem + ')').join(', ') + ')' : null;
    }
    return null;
  }

  getRecipienten(staal: any, voorkeur: boolean): string | null {
    const recipienten: any[] | null = staal.recipient;
    return (recipienten && recipienten.length) ? recipienten.filter(recipient => voorkeur ? recipient.voorkeur : !recipient.voorkeur).map(recipient => recipient.naam).join(', ') : null;
  }

  getAanvraagnummer(entiteit: any): number | null {
    const aanvragen: any[] | null = entiteit.aanvraag;
    return (aanvragen && aanvragen.length) ? aanvragen[0].nummer : null;
  }
}
