import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {User} from "@angular/fire/auth";
import {Observable} from "rxjs";
import {AuthenticationService} from "../core/authentication.service";

@Component({
  selector: "app-login",
  standalone: true,
  imports: [CommonModule],
  template: `
    @if (user$ | async; as user) {
      <div>
        Gebruiker: {{user.displayName}}
        <button (click)="logout()">Logout</button>
      </div>
    } @else {
      <div>
        <button (click)="login()">Inloggen met Cumulus account</button>
      </div>
    }
  `
})
export class LoginComponent implements OnInit {
  user$: Observable<User | null>;

  constructor(private readonly auth: AuthenticationService) {
  }

  ngOnInit(): void {
    this.user$ = this.auth.user;
  }

  login(): void {
    this.auth.login();
  }

  logout(): void {
    this.auth.logout();
  }
}
