import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {Overzicht} from "./overzicht";
import {DatabaseService} from "../core/database.service";
import {HistoriekEntry} from "../core/historiek-entry";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {PaginaOphalenStatus} from "../shared/pagina/pagina-ophalen-status";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekTools} from "../shared/boek/boek-tools";
import {BoekType} from "../shared/boek/boek-type";
import {BoekVersie} from "../shared/boek/boek-versie";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {RboekDataComponent} from "../rboek/rboek-data.component";
import {RboekFooterComponent} from "../rboek/rboek-footer.component";

@Component({
  selector: "app-overzicht-rboek-rapport",
  standalone: true,
  imports: [CommonModule, PaginaOphalenComponent, RboekDataComponent, RboekFooterComponent],
  template: `
    <app-pagina-ophalen>
      @if (entiteiten$ | async; as entiteiten) {
        <table>
          <tr>
            <td class="footer">
              LABORATORIUMGENEESKUNDE UNIVERSITAIRE ZIEKENHUIZEN LEUVEN<br/>
              Referentiewaarden geldig op {{datum}}
            </td>
          </tr>
        </table>
        <br/>
        <div [class.loader]="historiekenLoadingCount"></div>
        @for (entiteit of entiteiten; track $index) {
          <div [class.hidden]="historiekenLoadingCount">
            <app-rboek-data [entiteit]="entiteit" [versie]="versie"></app-rboek-data>
            <app-rboek-footer [versie]="versie" [locatie]="locatie" [code]="entiteit.code"
                              [aanvraagNummer]="getAanvraagNummer(entiteit)" [historiek$]="getHistoriek(entiteit.code)"
                              [showLinks]="false">
            </app-rboek-footer>
            @if (!$last) {
              <br/>
            }
          </div>
        }
      }
    </app-pagina-ophalen>
  `,
  styleUrl: "./overzicht.css"
})
export class OverzichtRboekRapportComponent implements Overzicht, OnInit {
  readonly type: BoekType = "rboek";

  entiteiten$: Observable<any[]>;
  versie: BoekVersie;
  locatie: BoekLocatie;
  datum: string;
  private nummers: number[];
  private readonly historieken = new Map<number, Observable<HistoriekEntry[]>>();
  historiekenLoadingCount = 0;

  constructor(private readonly logger: LoggerService,
              private readonly pagina: PaginaService,
              private readonly db: DatabaseService,
              private readonly route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.entiteiten$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap): Observable<any[]> => {
        this.pagina.setStatus(PaginaOphalenStatus.BUSY);
        const versie = BoekTools.getVersie(params);
        if (!versie) {
          throw new Error("Versie is vereist");
        }
        this.versie = versie;
        const locatie = BoekTools.getLocatie(params);
        if (!locatie) {
          throw new Error("Locatie is vereist");
        }
        this.locatie = locatie;
        this.datum = new Date().toLocaleString();
        this.nummers = this.parseNummers(params.get("nummers"));
        this.historieken.clear();
        return this.db.getEntiteiten(this.type, this.versie, this.locatie).pipe(
          map(entiteiten => this.filterEntiteiten(entiteiten))
        );
      }),
      tap(() => this.pagina.setStatus(PaginaOphalenStatus.SUCCEEDED)),
      catchError(err => {
        this.logger.error(err);
        this.pagina.setStatus(PaginaOphalenStatus.FAILED);
        return of([]);
      })
    );
  }

  private parseNummers(s: string | null): number[] {
    if (!s) {
      return [];
    }
    return s.split(",")
      .map(aanvraag => +aanvraag)
      .filter(nummer => nummer);
  }

  private filterEntiteiten(entiteiten: any[]): any[] {
    const filtered: any[] = entiteiten.filter(entiteit =>
      this.getAanvraagNummers(entiteit).some(nummer => this.nummers.indexOf(nummer) >= 0)
    ).sort((e1, e2) =>
      this.getMinAanvraagNummerIndex(e1) - this.getMinAanvraagNummerIndex(e2)
    );
    this.historiekenLoadingCount = filtered.length;
    return filtered;
  }

  private getAanvraagNummers(entiteit: any): number[] {
    const aanvragen: any[] | null = entiteit.aanvraag;
    return aanvragen ? aanvragen.map(aanvraag => aanvraag.nummer) : [];
  }

  private getMinAanvraagNummerIndex(entiteit: any): number {
    return this.getAanvraagNummers(entiteit)
      .map(nummer => this.nummers.indexOf(nummer))
      .filter(idx => idx >= 0)
      .reduce((acc, cur) => Math.min(acc, cur));
  }

  getAanvraagNummer(entiteit: any): number | null {
    const aanvragen: any[] | null = entiteit.aanvraag;
    return (aanvragen && aanvragen.length) ? aanvragen[0].nummer : null;
  }

  getHistoriek(code: number): Observable<HistoriekEntry[]> {
    let historiek$ = this.historieken.get(code);
    if (historiek$) {
      return historiek$;
    }
    historiek$ = this.db.getHistoriekOverzicht(this.type, this.versie, this.locatie, code).pipe(
      tap(() => this.historiekenLoadingCount--)
    );
    this.historieken.set(code, historiek$);
    return historiek$;
  }
}
