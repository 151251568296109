import {Component} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute, RouterModule} from "@angular/router";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {OverzichtPboekRapport} from "./overzicht-pboek-rapport";
import {RapportRij} from "./rapport-rij";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {ExportTableComponent} from "../shared/export/export-table.component";

@Component({
  selector: "app-overzicht-scope",
  standalone: true,
  imports: [CommonModule, RouterModule, PaginaOphalenComponent, ExportTableComponent],
  template: `
    <app-pagina-ophalen>
      @if (tabel$ | async; as tabel) {
        <app-export-table [table]="exportTable" filename="scope"></app-export-table>
        <table #exportTable class="border">
          <caption>Scope</caption>
          <tr>
            <th class="header">nummer</th>
            <th class="cell">testnaam</th>
            <th class="cell">aanvraagnummer</th>
            <th class="cell">verantwoordelijke</th>
            <th class="cell">afnamemateriaal</th>
            <th class="cell">opnemen in scooptabel?</th>
            <th class="cell">beproevingsmethode</th>
            <th class="cell">toestel</th>
            <th class="cell">toepassingsgebied</th>
            <th class="cell">SOP</th>
            <th class="cell">accreditatie</th>
          </tr>
          @for (rij of tabel; track $index) {
            <tr>
              <td class="header code-field">
                <a [routerLink]="['/', type, versie, locatie, rij.entiteitCode]">{{rij.entiteitCode}}</a>
              </td>
              <td class="cell">{{rij.entiteitOmschrijving}}</td>
              <td class="cell">
                @for (aanvraagNummer of rij.aanvraagNummers; track aanvraagNummer) {
                  {{aanvraagNummer}}
                  @if (!$last) {
                    <br/>
                  }
                }
              </td>
              <td class="cell">
                @for (verantwoordelijke of rij.verantwoordelijken; track $index) {
                  <a target="_blank" [href]="verantwoordelijke.wieIsWie">{{verantwoordelijke.naam}}</a>
                  @if (!$last) {
                    <br/>
                  }
                }
              </td>
              <td class="cell">
                @for (afnameMateriaal of rij.afnameMaterialen; track afnameMateriaal) {
                  {{afnameMateriaal}}
                  @if (!$last) {
                    <br/>
                  }
                }
              </td>
              @for (titel of scopeTitels; track titel) {
                <td class="cell">
                  @for (afnameInstructie of rij.afnameInstructiesMap.get(titel); track $index) {
                    @if (afnameInstructie.isHref) {
                      <a target="_blank" [href]="afnameInstructie.text">{{afnameInstructie.text}}</a>
                    } @else {
                      {{afnameInstructie.text}}
                    }
                    @if (!$last) {
                      <br/>
                    }
                  }
                </td>
              }
            </tr>
          }
        </table>
      }
    </app-pagina-ophalen>
  `,
  styleUrl: "./overzicht.css"
})
export class OverzichtScopeComponent extends OverzichtPboekRapport {
  readonly scopeTitels = ["Opnemen in scooptabel", "Beproevingsmethode", "Toestel",
    "Toepassingsgebied", "SOPnr", "Test onder accreditatie"];

  constructor(logger: LoggerService, pagina: PaginaService, db: DatabaseService, route: ActivatedRoute) {
    super(logger, pagina, db, route, entiteit => {
      return new RapportRij(entiteit.code,
        entiteit.omschrijving,
        [],
        this.getAfnameMaterialen(entiteit),
        [],
        this.getVerantwoordelijken(entiteit),
        this.getAfnameInstructiesMap(entiteit, this.scopeTitels),
        [],
        null,
        [],
        this.getAanvraagNummers(entiteit),
        [],
        [],
        [],
        []);
    });
  }
}
