import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Observable} from "rxjs";
import {PaginaOphalenStatus} from "./pagina-ophalen-status";
import {PaginaService} from "../../core/pagina.service";
import {PageNotFoundComponent} from "./page-not-found.component";

@Component({
  selector: "app-pagina-ophalen",
  standalone: true,
  imports: [CommonModule, PageNotFoundComponent],
  template: `
    @switch (status$ | async) {
      @case (PaginaOphalenStatus.TODO) {
        <p>Pagina zoeken...</p>
      }
      @case (PaginaOphalenStatus.BUSY) {
        <p>Pagina ophalen...</p>
      }
      @case (PaginaOphalenStatus.SUCCEEDED) {
        <ng-content></ng-content>
      }
      @case (PaginaOphalenStatus.FAILED) {
        <app-page-not-found></app-page-not-found>
      }
      @default {
        <app-page-not-found></app-page-not-found>
      }
    }
  `
})
export class PaginaOphalenComponent implements OnInit {
  PaginaOphalenStatus = PaginaOphalenStatus;
  status$: Observable<PaginaOphalenStatus>;

  constructor(private readonly pagina: PaginaService) {
  }

  ngOnInit(): void {
    this.status$ = this.pagina.status;
  }
}
