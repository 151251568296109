import {Component, EventEmitter, Output} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BoekLabo} from "../shared/boek/boek-labo";

@Component({
  selector: "app-labo",
  standalone: true,
  imports: [CommonModule],
  template: `
    <select #select (change)="emitLabo(select.value)" class="select">
      <option value="" selected>Toon alle resultaten</option>
      @for (labo of labos; track labo) {
        <option [value]="labo">Toon enkel resultaten voor {{labo}}</option>
      }
    </select>
  `,
  styleUrl: "./labo.component.css"
})
export class LaboComponent {
  readonly labos: BoekLabo[];
  @Output() labo = new EventEmitter<BoekLabo | null>();

  constructor() {
    this.labos = ["klinische biologie", "genetica", "pathologische ontleedkunde"];
    this.labos.sort();
  }

  emitLabo(value: string): void {
    const labo = value as BoekLabo || null;
    this.labo.emit(labo);
  }
}
