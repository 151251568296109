import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute, ParamMap, RouterModule} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {Overzicht} from "./overzicht";
import {PaginaOphalenStatus} from "../shared/pagina/pagina-ophalen-status";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekTools} from "../shared/boek/boek-tools";
import {BoekType} from "../shared/boek/boek-type";
import {BoekURL} from "../shared/boek/boek-url";
import {BoekVersie} from "../shared/boek/boek-versie";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {StorageService} from "../core/storage/storage.service";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {ExportTablesComponent} from "../shared/export/export-tables.component";

@Component({
  selector: "app-overzicht-formulier",
  standalone: true,
  imports: [CommonModule, RouterModule, PaginaOphalenComponent, ExportTablesComponent],
  template: `
    <app-pagina-ophalen>
      @if (entiteitGroepen$ | async; as entiteitGroepen) {
        <nav>
          <ul>
            @for (entiteitGroep of entiteitGroepen; track $index) {
              @if (entiteitGroep[0]; as formulier) {
                <li><a routerLink="." fragment="{{formulier.nummer}}">{{formulier.nummer}}</a></li>
              }
            }
          </ul>
        </nav>
        <app-export-tables [tables]="getSubTables(table)" filename="formulieren"></app-export-tables>
        <table #table class="border">
          <caption>Formulier</caption>
          @for (entiteitGroep of entiteitGroepen; track $index) {
            @if (entiteitGroep[0]; as formulier) {
              <tr>
                <td [id]="formulier.nummer">
                  <table class="border">
                    <caption class="header fragment-field">
                      &raquo;
                      @if (getFormulierDocument(formulier) | async; as document) {
                        <a target="_blank" [href]="document">{{formulier.nummer}}</a>
                      } @else {
                        {{formulier.nummer}}
                      }
                    </caption>
                    <tr>
                      <th class="header">nummer</th>
                      <th class="cell">testnaam</th>
                      <th class="cell">diagnose</th>
                      <th class="cell">procedure</th>
                      <th class="cell">prijs</th>
                      <th class="cell">super</th>
                    </tr>
                    @for (entiteit of entiteitGroep[1]; track $index) {
                      <tr>
                        <td class="header code-field">
                          <a [routerLink]="['/pboek/intralab', locatie, entiteit.code]">{{renderCell(entiteit.code)}}</a>
                        </td>
                        <td class="cell">{{renderCell(entiteit.omschrijving)}}</td>
                        <td class="cell boolean-field">{{renderCell(hasDiagnoseRegels(entiteit))}}</td>
                        <td class="cell boolean-field">{{renderCell(hasAfnameInstructies(entiteit))}}</td>
                        <td class="cell boolean-field">{{renderCell(hasNietZIVPrijs(entiteit))}}</td>
                        <td class="cell boolean-field">{{renderCell(hasVerantwoordelijke(entiteit))}}</td>
                      </tr>
                    }
                  </table>
                </td>
              </tr>
            }
          }
        </table>
      }
    </app-pagina-ophalen>
  `,
  styleUrl: "./overzicht.css"
})
export class OverzichtFormulierComponent implements Overzicht, OnInit {
  readonly type: BoekType = "pboek";
  readonly versie: BoekVersie = "intralab";

  entiteitGroepen$: Observable<Array<[any, any[]]>>;
  locatie: BoekLocatie;
  private readonly formulierDocumentCache = new Map<number, Promise<string>>;

  constructor(private readonly logger: LoggerService,
              private readonly pagina: PaginaService,
              private readonly db: DatabaseService,
              private readonly storage: StorageService,
              private readonly route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.entiteitGroepen$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap): Observable<Array<[any, any[]]>> => {
        this.pagina.setStatus(PaginaOphalenStatus.BUSY);
        const locatie = BoekTools.getLocatie(params);
        if (!locatie) {
          throw new Error("Locatie is vereist");
        }
        this.locatie = locatie;
        return this.db.getEntiteiten(this.type, this.versie, this.locatie).pipe(
          map(entiteiten => this.getEntiteitGroepen(entiteiten))
        )
      }),
      tap(() => this.pagina.setStatus(PaginaOphalenStatus.SUCCEEDED)),
      catchError(err => {
        this.logger.error(err);
        this.pagina.setStatus(PaginaOphalenStatus.FAILED);
        return of([]);
      })
    );
  }

  getFormulieren(entiteit: any): any[] {
    for (const element of entiteit.element) {
      if (element.type === "aanvraag") {
        const nummers: number[] = [];
        const formulieren: any[] = [];
        for (const aanvraag of element.aanvraag) {
          for (const formulier of aanvraag.formulier) {
            if (nummers.indexOf(formulier.nummer) < 0) {
              nummers.push(formulier.nummer);
              formulieren.push(formulier);
            }
          }
        }
        return formulieren;
      }
    }
    return [];
  }

  getFormulierDocument(formulier: any): Promise<string> {
    const nummer: number = formulier.nummer;
    let formulierDocument$: Promise<string> | undefined = this.formulierDocumentCache.get(nummer);
    if (!formulierDocument$) {
      formulierDocument$ = BoekURL.getFormulierDocument(this.storage, this.locatie, nummer);
      this.formulierDocumentCache.set(nummer, formulierDocument$);
    }
    return formulierDocument$;
  }

  hasDiagnoseRegels(entiteit: any): boolean {
    const elementen: any[] = entiteit.element;
    return elementen.some(element => element.type === "diagnoseRegel");
  }

  hasAfnameInstructies(entiteit: any): boolean {
    const elementen: any[] = entiteit.element;
    return elementen.some(element => element.type === "afnameInstructie");
  }

  hasNietZIVPrijs(entiteit: any): boolean {
    const elementen: any[] = entiteit.element;
    return elementen.some(element => element.type === "nietZIVPrijs");
  }

  hasVerantwoordelijke(entiteit: any): boolean {
    const elementen: any[] = entiteit.element;
    return elementen.some(element => element.type === "verantwoordelijke");
  }

  renderCell(value: any): string {
    if (typeof(value) === "boolean") {
      return value ? "*" : "";
    }
    return String(value);
  }

  getSubTables(table: HTMLTableElement): () => [HTMLTableElement, string][] {
    return () => Array.from(table.rows)
      .map((row): [HTMLTableElement, string] => {
        const cell = row.cells[0];
        const table: HTMLTableElement = cell.firstChild as HTMLTableElement;
        const id: string = cell.id;
        return [table, id];
      });
  }

  private getEntiteitGroepen(entiteiten: any[]): Array<[any, any[]]> {
    const formulierMap: Map<number, any> = new Map();
    const entiteitMap: Map<number, any[]> = entiteiten.sort(this.compareEntiteiten)
      .reduce((accumulator: Map<number, any[]>, entiteit: any) => {
        const formulieren: any[] = this.getFormulieren(entiteit);
        for (const formulier of formulieren) {
          const nummer: number = formulier.nummer;
          if (accumulator.has(nummer)) {
            accumulator.get(nummer)!.push(entiteit);
          } else {
            accumulator.set(nummer, [entiteit]);
            formulierMap.set(nummer, formulier);
          }
        }
        return accumulator;
      }, new Map());
    return Array.from(entiteitMap)
      .sort(this.compareFormulierEntries)
      .map((entry: [number, any[]]): [any, any[]] => {
        const nummer: number = entry[0];
        const entiteiten: any[] = entry[1];
        const formulier: any = formulierMap.get(nummer);
        return [formulier, entiteiten];
      });
  }

  private compareEntiteiten(e1: any, e2: any): number {
    const omschrijving1: string = e1.omschrijving;
    const omschrijving2: string = e2.omschrijving;
    return omschrijving1.localeCompare(omschrijving2);
  }

  private compareFormulierEntries(f1: [number, any[]], f2: [number, any[]]): number {
    const nummer1: number = f1[0];
    const nummer2: number = f2[0];
    return nummer1 - nummer2;
  }
}
