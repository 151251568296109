import {Component, Input} from "@angular/core";
import {ExporterService} from "../../core/exporter.service";

@Component({
  selector: "app-export-table",
  standalone: true,
  template: `
    <button (click)="exportTable()">Exporteer tabel</button>
  `,
  styleUrl: "./export-table.css"
})
export class ExportTableComponent {
  @Input() table: HTMLTableElement;
  @Input() filename: string;

  constructor(private readonly exporter: ExporterService) {
  }

  exportTable(): void {
    this.exporter.exportTableToXLSX(this.table, this.filename);
  }
}
