import {Component, OnInit} from "@angular/core";
import {Meta} from "@angular/platform-browser";
import {NavigationEnd, Router} from "@angular/router";
import {filter, map} from "rxjs/operators";
import {environment} from "../environments/environment";
import {BoekParams} from "./shared/boek/boek-params";
import {BoekVersie} from "./shared/boek/boek-versie";
import {SidebarContainerComponent} from "./sidebar/sidebar-container.component";

@Component({
  selector: "app-root",
  standalone: true,
  imports: [SidebarContainerComponent],
  template: `
    <div class="test-warn" [class.hidden]="hidden">
      BoekenToy<br/>
      PROD_LIS
    </div>
    <app-sidebar-container></app-sidebar-container>
  `,
  styleUrl: "./app.component.css"
})
export class AppComponent implements OnInit {
  readonly hidden: boolean = environment.production;

  constructor(private readonly router: Router,
              private readonly meta: Meta) {
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => (event as NavigationEnd).url),
      map(url => BoekParams.from(url))
    ).subscribe(boekParams => this.setRobots(boekParams.versie));
  }

  private setRobots(versie: BoekVersie | null): void {
    if (environment.production && versie === "internet") {
      this.meta.removeTag("name='robots'")
    } else {
      this.meta.addTag({name: "robots", content: "none"})
    }
  }
}
