import {Component, OnDestroy, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {Data, NavigationEnd, NavigationStart, Router, RouterModule} from "@angular/router";
import {Subject} from "rxjs";
import {filter, map, takeUntil} from "rxjs/operators";
import {PaginaService} from "../core/pagina.service";

@Component({
  selector: "app-sidebar-container",
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `
    @if (sidebarVisible) {
      <div class="sidebar">
        <div class="sidebar-icon" (click)="flipSidebar()">
          <div class="bar"></div>
          <div class="bar"></div>
          <div class="bar"></div>
        </div>
        <div class="sidebar-outlet" [class.sidebar-open]="sidebarOpen">
          <router-outlet name="sidebar"></router-outlet>
        </div>
      </div>
    }
    <div class="content" [class.sidebar-open]="sidebarVisible && sidebarOpen">
      <router-outlet #contentOutlet="outlet" (activate)="inspectData(contentOutlet.activatedRouteData)"></router-outlet>
    </div>
  `,
  styleUrl: "./sidebar-container.component.css"
})
export class SidebarContainerComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  sidebarVisible = true;
  sidebarOpen = true;

  constructor(private readonly router: Router,
              private readonly pagina: PaginaService) {
  }

  ngOnInit(): void {
    this.router.events.pipe(
      filter(event => event instanceof NavigationStart),
      takeUntil(this.destroy$)
    ).subscribe(() => this.pagina.resetParams());

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(event => (event as NavigationEnd).url),
      takeUntil(this.destroy$)
    ).subscribe(url => this.pagina.setParams(url));
  }

  inspectData(data: Data): void {
    this.sidebarVisible = data["sidebar"] !== false;
  }

  flipSidebar(): void {
    this.sidebarOpen = !this.sidebarOpen
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }
}
