import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-pboek-synoniem",
  standalone: true,
  imports: [CommonModule],
  template: `
    @for (s of element.synoniem; track $index) {
      <span>{{s.omschrijving}}</span>
      @if (!$last) {
        <span>; </span>
      }
    }
  `
})
export class PboekSynoniemComponent {
  @Input() element: any;
}
