import {Component, Input, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {BehaviorSubject, combineLatest, Observable, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, map, switchMap} from "rxjs/operators";
import {Zoekterm} from "./zoekterm";
import {BoekLabo} from "../shared/boek/boek-labo";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekTools} from "../shared/boek/boek-tools";
import {BoekType} from "../shared/boek/boek-type";
import {BoekVersie} from "../shared/boek/boek-versie";
import {DatabaseService} from "../core/database.service";
import {LaboComponent} from "./labo.component";
import {LocatieComponent} from "./locatie.component";

@Component({
  selector: "app-zoeken",
  standalone: true,
  imports: [CommonModule, RouterModule, LaboComponent, LocatieComponent],
  template: `
    <table width="100%" border="0" class="border" cellspacing="1" cellpadding="4">
      <tr>
        @switch (type) {
          @case ("pboek") {
            <td class="header">LABOGIDS</td>
          }
          @case ("rboek") {
            <td class="header">LABO referentiewaarden</td>
          }
          @case ("eboek") {
            <td class="header">LABO aanvraagmenu</td>
          }
          @default {
            <td class="header">LABOBOEK</td>
          }
        }
      </tr>
      <tr>
        <td class="cell" style="vertical-align:middle;">
          <input #zoekterm type="text" placeholder="Zoeken" autofocus (keyup)="zoeken(zoekterm.value, !fuzzy.checked)"/>
          @if (versie !== "internet") {
            <app-locatie class="locatie" [type]="type" [versie]="versie" [locatie]="locatie" [code]="code">
            </app-locatie>
          }
          @if (toonLaboSelector()) {
            <app-labo (labo)="labo=$event"></app-labo>
          }
        </td>
      </tr>
    </table>
    <table width="100%">
      <tr>
        <td class="footer">
          <label for="fuzzy">zoek enkel letterlijk</label>
          <input #fuzzy id="fuzzy" type="checkbox" (change)="zoeken(zoekterm.value, !fuzzy.checked)"/>
        </td>
      </tr>
    </table>
    <br/>
    <table width="100%" border="0" class="border" cellspacing="1" cellpadding="4">
      <tr>
        <td class="header">Resultaten</td>
      </tr>
      <tr>
        <td class="cell">
          @if (entiteiten$ | async; as entiteiten) {
            @if (entiteiten.length) {
              <table border="0" cellspacing="1" cellpadding="1" class="scrolling-table">
                @for (entiteit of entiteiten; track $index) {
                  <tr>
                    <td style="text-align:right; vertical-align:top;">
                      <b>{{$index + 1}}.</b>
                    </td>
                    <td>
                      @if (locatie) {
                        <a [routerLink]="['/', type, versie, locatie, entiteit.code]">{{entiteit.omschrijving}}</a>
                      } @else {
                        <a [routerLink]="['/', type, versie, entiteit.code]">{{entiteit.omschrijving}}</a>
                      }
                    </td>
                  </tr>
                }
              </table>
            } @else if (zoekterm.value) {
              Geen resultaten gevonden
              @if (locatie) {
                voor locatie {{locatie}}
              }
              @if (labo) {
                en labo {{labo}}
              }
            }
          }
        </td>
      </tr>
    </table>
  `,
  styleUrl: "./zoeken.component.css"
})
export class ZoekenComponent implements OnInit {
  @Input() type: BoekType;
  @Input() versie: BoekVersie;
  private locatie$ = new BehaviorSubject<BoekLocatie | null>(null);
  private _locatie: BoekLocatie | null = null;
  @Input() code: number | null;

  entiteiten$: Observable<any[]>;
  private zoekterm$ = new Subject<Zoekterm>();
  private labo$ = new BehaviorSubject<BoekLabo | null>(null);
  private _labo: BoekLabo | null = null;

  constructor(private readonly db: DatabaseService) {
  }

  get locatie(): BoekLocatie | null {
    return this._locatie;
  }

  @Input()
  set locatie(locatie: BoekLocatie | null) {
    if (this._locatie !== locatie) {
      this._locatie = locatie;
      this.locatie$.next(locatie);
      if (!this.toonLaboSelector()) {
        this.labo = null;
      }
    }
  }

  zoeken(term: string, fuzzy: boolean): void {
    this.zoekterm$.next(new Zoekterm(term, fuzzy));
  }

  toonLaboSelector(): boolean {
    return this.locatie === "GHB";
  }

  get labo(): BoekLabo | null {
    return this._labo;
  }

  set labo(labo: BoekLabo | null) {
    if (this._labo !== labo) {
      this._labo = labo;
      this.labo$.next(labo);
    }
  }

  ngOnInit(): void {
    const entiteitenAlleLabos$: Observable<any[]> = combineLatest([this.zoekterm$, this.locatie$]).pipe(
      debounceTime(300), // wacht minstens 300ms tussen zoekopdrachten
      distinctUntilChanged(([zoekterm1, locatie1], [zoekterm2, locatie2]) =>
        zoekterm1.term === zoekterm2.term && zoekterm1.fuzzy === zoekterm2.fuzzy && locatie1 === locatie2
      ), // enkel zoeken als deze waarde verschilt van vorige
      switchMap(([zoekterm, locatie]) =>
        this.db.zoekEntiteiten(this.type, this.versie, locatie, zoekterm.term, zoekterm.fuzzy)
      )
    );
    this.entiteiten$ = combineLatest([entiteitenAlleLabos$, this.labo$]).pipe(
      map(([entiteiten, labo]) =>
        labo ? entiteiten.filter(entiteit => BoekTools.getLabo(entiteit.locatie) === labo) : entiteiten
      )
    );
  }
}
