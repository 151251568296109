import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {StorageFile} from "../core/storage/storage-file";
import {StorageService} from "../core/storage/storage.service";

@Component({
  selector: "app-bijlage",
  standalone: true,
  imports: [CommonModule],
  template: `
    @if (!(storageFile$ | async)) {
      @if (fileNotFound) {
        <p>Bijlage niet gevonden</p>
      } @else {
        <p>Bijlage ophalen...</p>
      }
    }
  `
})
export class BijlageComponent implements OnInit {
  storageFile$: Promise<StorageFile>;
  fileNotFound: boolean;

  constructor(private readonly route: ActivatedRoute,
              private readonly router: Router,
              private readonly storage: StorageService) {
  }

  ngOnInit(): void {
    const params: ParamMap = this.route.snapshot.paramMap;
    const directory: string | null = params.get("directory");
    if (!directory) {
      throw new Error("Directory is vereist");
    }
    const file: string | null = params.get("file");
    if (!file) {
      throw new Error("File is vereist");
    }
    const path = `/${directory}/${file}`;
    this.storageFile$ = this.storage.getFile(path)
        .then((storageFile: StorageFile) => {
          window.location.href = storageFile.url;
          return storageFile;
        })
        .catch(err => {
          this.fileNotFound = true;
          throw err;
        });
  }
}
