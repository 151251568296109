import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BoekVersie} from "../shared/boek/boek-versie";

@Component({
  selector: "app-rboek-test",
  standalone: true,
  imports: [CommonModule],
  template: `
    <table cellpadding="0" cellspacing="0" width="100%">
      <tr>
        <th class="special3">geslacht</th><th class="special3">leeftijd</th><th class="special3">waarde</th><th class="special3">eenheid</th><th class="special3">categorie</th><th class="special3">commentaar</th>
        @if (versie !== "internet") {
          <th class="special3">ziekenhuis</th><th class="special3">eenheidsgroep</th><th class="special3">afdeling</th>
        }
      </tr>
      @for (limiet of test.limiet; track $index) {
        <tr>
          <td class="special3">{{limiet.geslacht}}</td><td class="special3">{{limiet.leeftijd}}</td><td class="special3">{{limiet.waarde}}</td><td class="special3">{{limiet.eenheid}}</td><td class="special3">{{limiet.categorie}}</td><td class="special3 preserve-text">{{limiet.commentaar}}</td>
          @if (versie !== "internet") {
            <td class="special3">{{limiet.ziekenhuis}}</td><td class="special3">{{limiet.eenheidsgroep}}</td><td class="special3">{{limiet.afdeling}}</td>
          }
        </tr>
      }
    </table>
    @if (test.verborgenLimieten) {
      <br/><p>Opmerking: er zijn ook andere referentiewaarden in gebruik</p>
    }
  `
})
export class RboekTestComponent {
  @Input() test: any;
  @Input() versie: BoekVersie;
}
