<app-pagina-ophalen>
  @if (entiteit$ | async; as entiteit) {
    <div class="historiek">
      @if (entiteit.verwijderd) {
        <h2>Pagina werd verwijderd</h2>
      } @else {
        <app-eboek-data [entiteit]="entiteit">
        </app-eboek-data>
      }
      <app-eboek-historiek-footer [versie]="versie" [code]="code" [datum]="datum"
                                  [historiek$]="historiek$">
      </app-eboek-historiek-footer>
    </div>
  }
</app-pagina-ophalen>
