import {Component, Input} from "@angular/core";
import {ExporterService} from "../../core/exporter.service";

@Component({
  selector: "app-export-tables",
  standalone: true,
  template: `
    <button (click)="exportTables()">Exporteer tabellen</button>
  `,
  styleUrl: "./export-table.css"
})
export class ExportTablesComponent {
  @Input() tables: [HTMLTableElement, string][] | (() => [HTMLTableElement, string][]);
  @Input() filename: string;

  constructor(private readonly exporter: ExporterService) {
  }

  exportTables(): void {
    this.exporter.exportTablesToXLSX(this.resolvedTables, this.filename);
  }

  private get resolvedTables(): [HTMLTableElement, string][] {
    return Array.isArray(this.tables) ? this.tables : this.tables();
  }
}
