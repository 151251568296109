import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute, ParamMap} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {Overzicht} from "./overzicht";
import {PaginaOphalenStatus} from "../shared/pagina/pagina-ophalen-status";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekTools} from "../shared/boek/boek-tools";
import {BoekType} from "../shared/boek/boek-type";
import {BoekURL} from "../shared/boek/boek-url";
import {BoekVersie} from "../shared/boek/boek-versie";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {StorageService} from "../core/storage/storage.service";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {ExportTableComponent} from "../shared/export/export-table.component";

@Component({
  selector: "app-overzicht-collectiemateriaal",
  standalone: true,
  imports: [CommonModule, PaginaOphalenComponent, ExportTableComponent],
  template: `
    <app-pagina-ophalen>
      @if (collectiematerialen$ | async; as collectiematerialen) {
        <app-export-table [table]="table" filename="collectiematerialen"></app-export-table>
        <table #table class="border">
          <caption>Collectiemateriaal</caption>
          <tr>
            <th class="header">afkorting</th>
            <th class="header">foto</th>
            <th class="cell">naam</th>
            <th class="cell">artikelnummer niet voorgebarcodeerd</th>
            <th class="cell">artikelnummer voorgebarcodeerd</th>
            <th class="cell">verkrijgbaar via</th>
          </tr>
          @for (collectiemateriaal of collectiematerialen; track $index) {
            <tr>
              <td class="header">{{collectiemateriaal.afkorting}}</td>
              <td class="header">
                @if (getFoto(collectiemateriaal) | async; as foto) {
                  <a [href]="foto" target="_blank"><img width="50" [src]="foto"/></a>
                }
              </td>
              <td class="cell">{{collectiemateriaal.naam}}</td>
              <td class="cell">{{collectiemateriaal.artikelnummer}}</td>
              <td class="cell">{{collectiemateriaal.artikelnummerVoorgebarcodeerd}}</td>
              <td class="cell">{{collectiemateriaal.verkrijgbaarVia}}</td>
            </tr>
          }
        </table>
      }
    </app-pagina-ophalen>
  `,
  styleUrl: "./overzicht.css"
})
export class OverzichtCollectiemateriaalComponent implements Overzicht, OnInit {
  readonly type: BoekType = "pboek";

  collectiematerialen$: Observable<any[]>;
  versie: BoekVersie;
  locatie: BoekLocatie;
  private readonly fotoCache = new Map<string, Promise<string>>();

  constructor(private readonly logger: LoggerService,
              private readonly pagina: PaginaService,
              private readonly db: DatabaseService,
              private readonly storage: StorageService,
              private readonly route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.collectiematerialen$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap): Observable<any[]> => {
        this.pagina.setStatus(PaginaOphalenStatus.BUSY);
        const versie = BoekTools.getVersie(params);
        if (!versie) {
          throw new Error("Versie is vereist");
        }
        this.versie = versie;
        const locatie = BoekTools.getLocatie(params);
        if (!locatie) {
          throw new Error("Locatie is vereist");
        }
        this.locatie = locatie;
        return this.db.getEntiteiten(this.type, this.versie, this.locatie).pipe(
          map(entiteiten => this.getCollectieMaterialen(entiteiten))
        )
      }),
      tap(() => this.pagina.setStatus(PaginaOphalenStatus.SUCCEEDED)),
      catchError(err => {
        this.logger.error(err);
        this.pagina.setStatus(PaginaOphalenStatus.FAILED);
        return of([]);
      })
    );
  }

  getFoto(collectiemateriaal: any): Promise<string> {
    const afkorting: string = collectiemateriaal.afkorting;
    let foto$: Promise<string> | undefined = this.fotoCache.get(afkorting);
    if (!foto$) {
      foto$ = BoekURL.getCollectiemateriaalFoto(this.storage, this.locatie, afkorting);
      this.fotoCache.set(afkorting, foto$);
    }
    return foto$;
  }

  private getCollectieMaterialen(entiteiten: any[]): any[] {
    const collectiematerialen: any[] = entiteiten.reduce((accumulator, entiteit) => {
      return accumulator.concat(this.getCollectieMaterialenEntiteit(entiteit));
    }, []);
    return this.distinctCollectiematerialen(collectiematerialen)
      .sort(this.compareCollectiematerialen);
  }

  private getCollectieMaterialenEntiteit(entiteit: any): any[] {
    for (const element of entiteit.element) {
      if (element.type === "collectiemateriaal") {
        return element.collectiemateriaal.reduce((accumulator: any[], materiaal: any) => {
          return accumulator.concat(materiaal.collectiemateriaalAlternatief);
        }, []);
      }
    }
    return [];
  }

  private distinctCollectiematerialen(collectiematerialen: any[]): any[] {
    const entries: [string, any][] = collectiematerialen.map((materiaal: any): [string, any] => {
      return [materiaal.naam, materiaal];
    });
    return Array.from(new Map(entries).values());
  }

  private compareCollectiematerialen(m1: any, m2: any): number {
    const a1: string = m1.afkorting;
    const a2: string = m2.afkorting;
    return a1.localeCompare(a2);
  }
}
