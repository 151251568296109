import {Component, inject, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {StorageService} from "../core/storage/storage.service";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekURL} from "../shared/boek/boek-url";

@Component({
  selector: "app-pboek-collectiemateriaal",
  standalone: true,
  imports: [CommonModule],
  template: `
    @for (c of element.collectiemateriaal; track $index) {
      <div [class]="c.plaats">
        @if (c.aantal > 1) {
          <b><i>{{c.aantal}}x </i></b>
        }
        @if (element.collectiemateriaal.length > 1 && c.naam) {
          <b>{{c.naam}}</b>
        }
        @if (c.aantal > 1 || (element.collectiemateriaal.length > 1 && c.naam)) {
          <br/>
        }
        @for (a of c.collectiemateriaalAlternatief; track $index) {
          {{a.naam}}
          @if (getFoto(a) | async; as foto) {
            <span>(<a [href]="foto" target="_blank">{{a.afkorting}}</a>)</span>
          } @else {
            <span>({{a.afkorting}})</span>
          }
          @if (!$last) {
            <span>,</span>
          }
        }
        @if (!$last) {
          <hr/>
        }
      </div>
    }
  `
})
export class PboekCollectiemateriaalComponent {
  @Input() element: any;
  @Input() locatie: BoekLocatie;
  private readonly storage = inject(StorageService);
  private readonly fotoCache = new Map<string, Promise<string>>();

  getFoto(collectiemateriaal: any): Promise<string> {
    const afkorting: string = collectiemateriaal.afkorting;
    let foto$: Promise<string> | undefined = this.fotoCache.get(afkorting);
    if (!foto$) {
      foto$ = BoekURL.getCollectiemateriaalFoto(this.storage, this.locatie, afkorting);
      this.fotoCache.set(afkorting, foto$);
    }
    return foto$;
  }
}
