import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {Observable} from "rxjs";
import {BoekURL} from "../shared/boek/boek-url";
import {BoekVersie} from "../shared/boek/boek-versie";
import {HistoriekEntry} from "../core/historiek-entry";

@Component({
  selector: "app-eboek-historiek-footer",
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `
    @if (historiek$ | async; as historiek) {
      <table width="100%">
        <tr>
          <td class="footer historiek-data">
            <div>historiek</div>
            @for (entry of historiek; track $index) {
              @if (entry.datum === datum) {
                <b>{{entry.datum}}</b>
              } @else if ($first) {
                <a [routerLink]="getEntiteitLink()">{{entry.datum}}</a>
              } @else if (entry.verwijderd) {
                {{entry.datum}} (verwijderd)
              } @else if (entry.prehistorie) {
                @if (versie === "internet") {
                  {{entry.datum}}
                } @else {
                  <a [href]="getHistoriekLink(entry)" target="_blank">{{entry.datum}}</a>
                }
              } @else {
                <a [routerLink]="getHistoriekLink(entry)">{{entry.datum}}</a>
              }
              <br/>
            }
          </td>
        </tr>
      </table>
    }
  `
})
export class EboekHistoriekFooterComponent {
  @Input() versie: BoekVersie;
  @Input() code: number;
  @Input() datum: string;
  @Input() historiek$: Observable<HistoriekEntry[]>;

  getEntiteitLink(): string {
    return BoekURL.getEntiteitLink("eboek", this.versie, null, this.code);
  }

  getHistoriekLink(entry: HistoriekEntry): string {
    return BoekURL.getHistoriekLink("eboek", this.versie, null, this.code, entry.datum, entry.prehistorie);
  }
}
