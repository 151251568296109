import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-pboek-uitvoeringstijd",
  standalone: true,
  imports: [CommonModule],
  template: `
    @for (u of element.uitvoeringstijd; track $index) {
      <div [class]="u.plaats">
        {{u.uitvoeringstijd}}<br/>
      </div>
    }
  `
})
export class PboekUitvoeringstijdComponent {
  @Input() element: any;
}
