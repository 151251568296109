import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekVersie} from "../shared/boek/boek-versie";

@Component({
  selector: "app-pboek-meesleur",
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `
    @for (m of element.meesleur; track $index) {
      <div [class]="m.plaats">
        <a [routerLink]="['/pboek', versie, locatie, m.refCode]">{{m.refNaam}}</a><br/>
      </div>
    }
  `
})
export class PboekMeesleurComponent {
  @Input() element: any;
  @Input() versie: BoekVersie;
  @Input() locatie: BoekLocatie;
}
