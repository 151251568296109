import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-pboek-uitvoerfrequentie",
  standalone: true,
  imports: [CommonModule],
  template: `
    @for (u of element.uitvoerfrequentie; track $index) {
      <div [class]="u.plaats">
        {{u.uitvoerfrequentie}}<br/>
      </div>
    }
  `
})
export class PboekUitvoerfrequentieComponent {
  @Input() element: any;
}
