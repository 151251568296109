import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-pboek-uitvoerend-labo",
  standalone: true,
  imports: [CommonModule],
  template: `
    @for (l of element.uitvoerendLabo; track $index) {
      {{l.naam}}<br/>
    }
  `
})
export class PboekUitvoerendLaboComponent {
  @Input() element: any;
}
