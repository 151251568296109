import {Injectable} from "@angular/core";
import {Database, listVal, objectVal, ref} from "@angular/fire/database";
import {combineLatest, Observable, of} from "rxjs";
import {first, map, tap} from "rxjs/operators";
import {Matcher} from "./zoeker/matcher";
import {BoekLocatie, DatabaseBoekLocatie} from "../shared/boek/boek-locatie";
import {BoekTools} from "../shared/boek/boek-tools";
import {BoekType} from "../shared/boek/boek-type";
import {BoekVersie} from "../shared/boek/boek-versie";
import {HistoriekEntry} from "./historiek-entry";
import {LoggerService} from "./logger.service";

@Injectable({
  providedIn: "root"
})
export class DatabaseService {
  private static readonly LABOBOEKEN: string = "/laboboeken";
  private static readonly HISTORIEK: string = "/laboboeken/historiek";
  private static readonly PREHISTORIE: string = "/laboboeken/prehistorie";
  private static readonly ZOEKEN: string = "/laboboeken/zoeken";

  constructor(private readonly logger: LoggerService,
              private readonly db: Database) {
  }

  getEntiteit(type: BoekType, versie: BoekVersie, locatie: BoekLocatie | null, code: number): Observable<any | null> {
    return this.createObjectObservable(DatabaseService.LABOBOEKEN, type, versie, locatie, code, null).pipe(
      tap(() => this.logger.info("DB: getEntiteit", type, versie, locatie, code))
    );
  }

  getEntiteitLink(type: BoekType, versie: BoekVersie, locatie: BoekLocatie | null, code: number)
    : Observable<string | null>
  {
    return this.createObjectObservable(DatabaseService.LABOBOEKEN, type, versie, locatie, code, null).pipe(
      tap(() => this.logger.info("DB: getEntiteitLink", type, versie, locatie, code)),
      map((entiteit: any | null) => entiteit
        ? `/${type}/${versie}` + (entiteit.locatie ? `/${entiteit.locatie}` : "") + `/${entiteit.code}`
        : null
      )
    );
  }

  getEntiteiten(type: BoekType, versie: BoekVersie, locatie: BoekLocatie | null): Observable<any[]> {
    return this.createListObservable(DatabaseService.LABOBOEKEN, type, versie, locatie, null).pipe(
      tap(() => this.logger.info("DB: getEntiteiten", type, versie, locatie)),
      first()
    );
  }

  getHistoriekOverzicht(type: BoekType, versie: BoekVersie, locatie: BoekLocatie | null, code: number)
    : Observable<HistoriekEntry[]>
  {
    const historiekObservable: Observable<HistoriekEntry[]>
      = this.createObjectObservable(DatabaseService.HISTORIEK, type, versie, locatie, code, null)
      .pipe(
        map((historiek: any | null) => {
          if (!historiek) {
            return [];
          }
          return Object.keys(historiek).sort().reverse().map((datum: string) => {
            const verwijderd: boolean = historiek[datum].verwijderd || false;
            return new HistoriekEntry(datum, verwijderd, false);
          });
        })
      );
    const prehistorieObservable: Observable<HistoriekEntry[]>
      = this.createListObservable<string>(DatabaseService.PREHISTORIE, type, versie, null, code)
      .pipe(
        map((historiek: string[]) => historiek.sort().reverse()
          .map((datum: string) => new HistoriekEntry(datum, false, true))
        )
      );
    return combineLatest([historiekObservable, prehistorieObservable]).pipe(
      tap(() => this.logger.info("DB: getHistoriekOverzicht", type, versie, locatie, code)),
      map((entries: [HistoriekEntry[], HistoriekEntry[]]) => entries[0].concat(entries[1]))
    );
  }

  getHistoriekEntiteit(type: BoekType, versie: BoekVersie, locatie: BoekLocatie | null, code: number, datum: string)
    : Observable<any | null>
  {
    return this.createObjectObservable(DatabaseService.HISTORIEK, type, versie, locatie, code, datum).pipe(
      tap(() => this.logger.info("DB: getHistoriekEntiteit", type, versie, locatie, code, datum))
    );
  }

  zoekEntiteiten(type: BoekType, versie: BoekVersie, locatie: BoekLocatie | null, zoekterm: string, fuzzy: boolean)
    : Observable<any[]>
  {
    if (!zoekterm.trim()) {
      return of([]);
    }
    const matcher: Matcher = new Matcher(zoekterm, fuzzy);
    return this.createListObservable(DatabaseService.ZOEKEN, type, versie, locatie, null).pipe(
      tap(() => this.logger.info("DB: zoekEntiteiten", type, versie, locatie, zoekterm, fuzzy)),
      first(),
      map((entiteiten: any[]) => entiteiten.filter(e => matcher.matches(e))
        .sort((e1: any, e2: any) => e1.omschrijving.localeCompare(e2.omschrijving))
      )
    );
  }

  getZoekenOverzicht(type: BoekType, versie: BoekVersie, locatie: BoekLocatie | null): Observable<any[]> {
    return this.createListObservable(DatabaseService.ZOEKEN, type, versie, locatie, null).pipe(
      tap(() => this.logger.info("DB: getZoekenOverzicht", type, versie, locatie)),
      first(),
      map((entiteiten: any[]) => entiteiten.sort((e1: any, e2: any) => e1.omschrijving.localeCompare(e2.omschrijving)))
    )
  }

  private createObjectObservable<T>(prefix: string,
                                    type: BoekType,
                                    versie: BoekVersie,
                                    locatie: BoekLocatie | null,
                                    code: number,
                                    datum: string | null): Observable<T | null> {
    const suffix = datum ? `/${datum}` : "";
    const dbLocaties: DatabaseBoekLocatie[] = locatie ? BoekTools.getDatabaseLocaties(locatie) : [];
    switch (dbLocaties.length) {
      case 0:
        return objectVal<T | null>(ref(this.db, `${prefix}/${type}/${versie}/${code}${suffix}`));
      case 1:
        return objectVal<T | null>(ref(this.db, `${prefix}/${type}/${versie}/${dbLocaties[0]}/${code}${suffix}`));
      default:
        const observables: Observable<T | null>[] = dbLocaties.map(l =>
          objectVal<T | null>(ref(this.db, `${prefix}/${type}/${versie}/${l}/${code}${suffix}`))
        );
        return combineLatest(observables).pipe(
          map((entries: (T | null)[]) => entries.find(e => e !== null) || null)
        );
    }
  }

  private createListObservable<T>(prefix: string,
                                  type: BoekType,
                                  versie: BoekVersie,
                                  locatie: BoekLocatie | null,
                                  code: number | null): Observable<T[]> {
    const suffix = code ? `/${code}` : "";
    const dbLocaties: DatabaseBoekLocatie[] = locatie ? BoekTools.getDatabaseLocaties(locatie) : [];
    switch (dbLocaties.length) {
      case 0:
        return listVal<T>(ref(this.db, `${prefix}/${type}/${versie}${suffix}`));
      case 1:
        return listVal<T>(ref(this.db, `${prefix}/${type}/${versie}/${dbLocaties[0]}${suffix}`));
      default:
        const observables: Observable<T[]>[] = dbLocaties.map(l =>
          listVal<T>(ref(this.db, `${prefix}/${type}/${versie}/${l}${suffix}`))
        );
        return combineLatest(observables).pipe(
          map((entries: T[][]) => ([] as T[]).concat(...entries))
        );
    }
  }
}
