import {Component, Input, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError} from "rxjs/operators";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekURL} from "../shared/boek/boek-url";
import {BoekVersie} from "../shared/boek/boek-versie";
import {DatabaseService} from "../core/database.service";
import {HistoriekEntry} from "../core/historiek-entry";
import {LoggerService} from "../core/logger.service";

@Component({
  selector: "app-rboek-footer",
  standalone: true,
  imports: [CommonModule, RouterModule],
  template: `
    @if (historiek$ | async; as historiek) {
      <table width="100%">
        <tr>
          <td class="footer boeken">
            Aanvraagnummer {{aanvraagNummer}}<br/>
            @if (showLinks) {
              @if (pboekLink$ | async; as pboekLink) {
                @if (versie === "internet") {
                  <a [routerLink]="pboekLink">labogids</a><br/>
                } @else {
                  <a [routerLink]="pboekLink">labogids (intramuros)</a><br/>
                  @if (pboekIntralabLink$ | async; as pboekIntralabLink) {
                    <a [routerLink]="pboekIntralabLink">labogids (intralab)</a><br/>
                  }
                }
              }
              @if (eboekLink$ | async; as eboekLink) {
                <a [routerLink]="eboekLink">aanvraagmenu</a>
              }
            }
          </td>
          <td class="footer historiek-data">
            <div>historiek</div>
            @for (entry of historiek; track $index) {
              @if (!$first) {
                @if (entry.verwijderd) {
                  {{entry.datum}} (verwijderd)
                } @else if (entry.prehistorie) {
                  @if (versie === "internet") {
                    {{entry.datum}}
                  } @else {
                    <a [href]="getHistoriekLink(entry)" target="_blank">{{entry.datum}}</a>
                  }
                } @else {
                  <a [routerLink]="getHistoriekLink(entry)">{{entry.datum}}</a>
                }
                <br/>
              }
            }
          </td>
          <td class="footer datum">
            @if (historiek.length) {
              {{historiek[0].datum}}
            }
            @if (feedback) {
              <br/><a target="_blank" [href]="feedback">feedback</a>
            }
          </td>
        </tr>
      </table>
    }
  `
})
export class RboekFooterComponent implements OnInit {
  @Input() versie: BoekVersie;
  @Input() locatie: BoekLocatie;
  @Input() code: number;
  @Input() aanvraagNummer: number | null;
  @Input() historiek$: Observable<HistoriekEntry[]>;
  @Input() feedback?: string;
  @Input() showLinks: boolean = true;
  pboekLink$: Observable<string | null>;
  pboekIntralabLink$: Observable<string | null>;
  eboekLink$: Observable<string | null>;

  constructor(private readonly logger: LoggerService,
              private readonly db: DatabaseService) {
  }

  ngOnInit(): void {
    this.pboekLink$ = this.db.getEntiteitLink("pboek", this.versie, this.locatie, this.code).pipe(
      catchError(err => {
        this.logger.error(err);
        return of(null);
      })
    );
    this.pboekIntralabLink$ = this.db.getEntiteitLink("pboek", "intralab", this.locatie, this.code).pipe(
      catchError(err => {
        this.logger.error(err);
        return of(null);
      })
    );
    this.eboekLink$ = this.db.getEntiteitLink("eboek", "internet", null, this.code).pipe(
      catchError(err => {
        this.logger.error(err);
        return of(null);
      })
    );
  }

  getHistoriekLink(entry: HistoriekEntry): string {
    return BoekURL.getHistoriekLink("rboek", this.versie, this.locatie, this.code, entry.datum, entry.prehistorie);
  }
}
