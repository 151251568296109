import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-pboek-riziv",
  standalone: true,
  imports: [CommonModule],
  template: `
    @for (r of element.riziv; track $index) {
      <div>
        {{r.nomenclatuurnr}}
        @if (!$last) {
          <hr/>
        }
      </div>
    }
  `
})
export class PboekRIZIVComponent {
  @Input() element: any;
}
