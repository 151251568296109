import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {RouterModule} from "@angular/router";
import {BoekVersie} from "../shared/boek/boek-versie";
import {RboekTestComponent} from "./rboek-test.component";

@Component({
  selector: "app-rboek-data",
  standalone: true,
  imports: [CommonModule, RouterModule, RboekTestComponent],
  template: `
    <table cellpadding="4" cellspacing="1" class="border" border="0" width="100%">
      <tr>
        <td class="header">{{entiteit.omschrijving}}</td>
      </tr>
      @if (entiteit.synoniem?.length) {
        <tr>
          <td class="cell" style="vertical-align:top;">
            @for (synoniem of entiteit.synoniem; track $index) {
              <span>{{synoniem.omschrijving}}</span>
              @if (!$last) {
                <span>; </span>
              }
            }
          </td>
        </tr>
      }
      @if (!entiteit.test?.length) {
        <tr>
          <td class="cell">Geen referentiewaarden!</td>
        </tr>
      }
      @if (entiteit.test?.length > 1) {
        <tr>
          <td class="cell">
            <ul>
              @for (test of entiteit.test; track $index) {
                <li><a routerLink="." [fragment]="'t' + test.code">{{test.omschrijving}}</a></li>
              }
            </ul>
          </td>
        </tr>
      }
      @for (test of entiteit.test; track $index) {
        <tr>
          <td class="subheader" style="vertical-align:top;"><a [id]="'t' + test.code">{{test.omschrijving}}</a></td>
        </tr>
        <tr>
          <td class="cell">
            <app-rboek-test [test]="test" [versie]="versie"></app-rboek-test>
          </td>
        </tr>
      }
    </table>
  `
})
export class RboekDataComponent {
  @Input() entiteit: any;
  @Input() versie: BoekVersie;
}
