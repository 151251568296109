import {Component, OnInit} from "@angular/core";
import {CommonModule} from "@angular/common";
import {ActivatedRoute, ParamMap, RouterModule} from "@angular/router";
import {Observable, of} from "rxjs";
import {catchError, map, switchMap, tap} from "rxjs/operators";
import {Overzicht} from "./overzicht";
import {PaginaOphalenStatus} from "../shared/pagina/pagina-ophalen-status";
import {BoekLocatie} from "../shared/boek/boek-locatie";
import {BoekTools} from "../shared/boek/boek-tools";
import {BoekType} from "../shared/boek/boek-type";
import {BoekURL} from "../shared/boek/boek-url";
import {BoekVersie} from "../shared/boek/boek-versie";
import {DatabaseService} from "../core/database.service";
import {LoggerService} from "../core/logger.service";
import {PaginaService} from "../core/pagina.service";
import {PaginaOphalenComponent} from "../shared/pagina/pagina-ophalen.component";
import {ExportTableComponent} from "../shared/export/export-table.component";

@Component({
  selector: "app-overzicht-niet-ziv",
  standalone: true,
  imports: [CommonModule, RouterModule, PaginaOphalenComponent, ExportTableComponent],
  template: `
    <app-pagina-ophalen>
      @if (entiteiten$ | async; as entiteiten) {
        <app-export-table [table]="table" filename="niet-ZIV prijslijst"></app-export-table>
        <table #table class="border">
          <caption>Niet-ZIV prijslijst</caption>
          <tr>
            <th class="cell">formulier</th>
            <th class="cell">aanvraag</th>
            <th class="cell">testnaam</th>
            <th class="cell">niet-ZIV prijs</th>
            <th class="cell">verantwoordelijke</th>
            <th class="cell">uitvoerder</th>
          </tr>
          @for (entiteit of entiteiten; track $index) {
            @if (getNietZIVPrijs(entiteit); as nietZIVPrijs) {
              <tr>
                <td class="cell code-field">{{getFormulierNummer(entiteit)}}</td>
                <td class="cell code-field">{{getAanvraagNummer(entiteit)}}</td>
                <td class="cell">
                  <a [routerLink]="['/', type, versie, locatie, entiteit.code]">{{entiteit.omschrijving}}</a>
                </td>
                <td class="cell">{{nietZIVPrijs | currency:"EUR"}}</td>
                <td class="cell">
                  @if (getVerantwoordelijke(entiteit); as verantwoordelijke) {
                    @if (versie === "internet") {
                      {{verantwoordelijke.voornaam}} {{verantwoordelijke.naam}}
                    } @else {
                      <a target="_blank" [href]="getWieIsWie(verantwoordelijke)">
                        {{verantwoordelijke.voornaam}} {{verantwoordelijke.naam}}
                      </a>
                    }
                  }
                </td>
                <td class="cell">{{getUitvoerendLabo(entiteit)}}</td>
              </tr>
            }
          }
        </table>
      }
    </app-pagina-ophalen>
  `,
  styleUrl: "./overzicht.css"
})
export class OverzichtNietZIVComponent implements Overzicht, OnInit {
  readonly type: BoekType = "pboek";

  entiteiten$: Observable<any[]>;
  versie: BoekVersie;
  locatie: BoekLocatie;

  constructor(private readonly logger: LoggerService,
              private readonly pagina: PaginaService,
              private readonly db: DatabaseService,
              private readonly route: ActivatedRoute) {
  }

  ngOnInit(): void {
    this.entiteiten$ = this.route.paramMap.pipe(
      switchMap((params: ParamMap): Observable<any[]> => {
        this.pagina.setStatus(PaginaOphalenStatus.BUSY);
        const versie = BoekTools.getVersie(params);
        if (!versie) {
          throw new Error("Versie is vereist");
        }
        this.versie = versie;
        const locatie = BoekTools.getLocatie(params);
        if (!locatie) {
          throw new Error("Locatie is vereist");
        }
        this.locatie = locatie;
        return this.db.getEntiteiten(this.type, this.versie, this.locatie).pipe(
          map(entiteiten => entiteiten.sort(this.compareEntiteiten.bind(this)))
        );
      }),
      tap(() => this.pagina.setStatus(PaginaOphalenStatus.SUCCEEDED)),
      catchError(err => {
        this.logger.error(err);
        this.pagina.setStatus(PaginaOphalenStatus.FAILED);
        return of([]);
      })
    );
  }

  getNietZIVPrijs(entiteit: any): number | null {
    for (const element of entiteit.element) {
      if (element.type === "nietZIVPrijs") {
        return element.nietZIVPrijs;
      }
    }
    return null;
  }

  getFormulierNummer(entiteit: any): number | null {
    const aanvraag: any | null = this.getAanvraag(entiteit);
    const formulieren: any[] = aanvraag ? aanvraag.formulier : [];
    return formulieren.length ? formulieren[0].nummer : null;
  }

  getAanvraagNummer(entiteit: any): number | null {
    const aanvraag: any | null = this.getAanvraag(entiteit);
    return aanvraag ? aanvraag.nummer : null;
  }

  private getAanvraag(entiteit: any): any | null {
    for (const element of entiteit.element) {
      if (element.type === "aanvraag") {
        return element.aanvraag[0];
      }
    }
    return null;
  }

  getVerantwoordelijke(entiteit: any): any | null {
    for (const element of entiteit.element) {
      if (element.type === "verantwoordelijke") {
        return element.verantwoordelijke[0];
      }
    }
    return null;
  }

  getWieIsWie(verantwoordelijke: any): string {
    return BoekURL.getWieIsWie(verantwoordelijke.loginnaam);
  }

  getUitvoerendLabo(entiteit: any): string | null {
    for (const element of entiteit.element) {
      if (element.type === "uitvoerendLabo") {
        return element.uitvoerendLabo[0].naam;
      }
    }
    return null;
  }

  private compareEntiteiten(e1: any, e2: any): number {
    const formulierNummer1: number = this.getFormulierNummer(e1) || Number.MAX_VALUE;
    const formulierNummer2: number = this.getFormulierNummer(e2) || Number.MAX_VALUE;
    let cmp = formulierNummer1 - formulierNummer2;
    if (cmp !== 0) {
      return cmp;
    }
    const aanvraagNummer1: number = this.getAanvraagNummer(e1) || Number.MAX_VALUE;
    const aanvraagNummer2: number = this.getAanvraagNummer(e2) || Number.MAX_VALUE;
    cmp = aanvraagNummer1 - aanvraagNummer2;
    if (cmp !== 0) {
      return cmp;
    }
    const omschrijving1: string = e1.omschrijving;
    const omschrijving2: string = e2.omschrijving;
    return omschrijving1.localeCompare(omschrijving2);
  }
}
