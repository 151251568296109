import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";
import {BoekURL} from "../shared/boek/boek-url";
import {BoekVersie} from "../shared/boek/boek-versie";

@Component({
  selector: "app-pboek-verantwoordelijke",
  standalone: true,
  imports: [CommonModule],
  template: `
    @for (v of element.verantwoordelijke; track $index) {
      @if (versie === "internet") {
        {{v.voornaam}} {{v.naam}}
      } @else {
        <a [href]="getWieIsWie(v)" target="_blank">{{v.voornaam}} {{v.naam}}</a>
      }
      @if (feedback && $first) {
        (<a target="_blank" [href]="feedback">feedback</a>)
      }
      <br/>
    }
  `
})
export class PboekVerantwoordelijkeComponent {
  @Input() element: any;
  @Input() versie: BoekVersie;
  @Input() feedback?: string;

  getWieIsWie(verantwoordelijke: any): string {
    return BoekURL.getWieIsWie(verantwoordelijke.loginnaam);
  }
}
