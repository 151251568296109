import {Component, Input} from "@angular/core";
import {CommonModule} from "@angular/common";

@Component({
  selector: "app-eboek-staal",
  standalone: true,
  imports: [CommonModule],
  template: `
    <table width="100%">
      @for (staal of stalen; track $index) {
        @if (staal.specimen; as specimen) {
          <tr>
            <td class="header">{{specimen}}</td>
          </tr>
        }
        @if (staal.omschrijving; as omschrijving) {
          <tr>
            <td class="cell">{{omschrijving}}</td>
          </tr>
        }
        <tr>
          <td>
            <table cellpadding="0" cellspacing="0" width="100%">
              @if (staal.aanvraagTest; as aanvraagTest) {
                <tr>
                  <td class="special2" colspan="2"><b>Aanvraagbaar en rapporteerbaar via</b></td>
                </tr>
                <tr>
                  <td class="special2">{{aanvraagTest.code}} ({{aanvraagTest.systeem}})</td>
                  <td class="special2"><i>{{aanvraagTest.naam}}</i></td>
                </tr>
              }
              @if (staal.and?.element; as aanvraagTesten) {
                <tr>
                  <td class="special2" colspan="3"><b>Aanvraagbaar en rapporteerbaar via</b></td>
                </tr>
                @for (aanvraagTest of aanvraagTesten; track $index) {
                  <tr>
                    @if ($first) {
                      <td class="special1" [attr.rowspan]="aanvraagTesten.length" width="1%">EN</td>
                    }
                    <td class="special2">{{aanvraagTest.code}} ({{aanvraagTest.systeem}})</td>
                    <td class="special2"><i>{{aanvraagTest.naam}}</i></td>
                  </tr>
                }
              }
              @if (staal.or?.element; as aanvraagTesten) {
                <tr>
                  <td class="special2" colspan="3"><b>Aanvraagbaar en rapporteerbaar via</b></td>
                </tr>
                @for (aanvraagTest of aanvraagTesten; track $index) {
                  <tr>
                    @if ($first) {
                      <td class="special1" [attr.rowspan]="aanvraagTesten.length" width="1%">OF</td>
                    }
                    <td class="special2">{{aanvraagTest.code}} ({{aanvraagTest.systeem}})</td>
                    <td class="special2"><i>{{aanvraagTest.naam}}</i></td>
                  </tr>
                }
              }
            </table>
          </td>
        </tr>
        <tr>
          <td>
            @if (staal.recipient; as recipienten) {
              <table width="100%">
                <tr>
                  <td colspan="2"><b>Recipiënten</b></td>
                </tr>
                @for (recipient of recipienten; track $index) {
                  <tr>
                    <td>{{recipient.naam}}</td>
                    @if (recipient.voorkeur) {
                      <td><i>voorkeur</i></td>
                    }
                  </tr>
                }
              </table>
            }
          </td>
        </tr>
        <tr>
          <td>
            @if (staal.toegelaten?.aanvraagTest; as aanvraagTesten) {
              <table width="100%">
                <tr>
                  <td colspan="2"><b>Rapporteerbaar via</b>
                    <span class="extra-left-margin tooltip">(niet aanvraagbaar)
                      <span class="tooltiptext">Deze codes mogen niet aangevraagd worden, maar dienen wel geaccepteerd te worden.</span>
                    </span>
                  </td>
                </tr>
                @for (aanvraagTest of aanvraagTesten; track $index) {
                  <tr>
                    <td>{{aanvraagTest.code}} ({{aanvraagTest.systeem}})</td>
                    <td><i>{{aanvraagTest.naam}}</i></td>
                  </tr>
                }
              </table>
            }
          </td>
        </tr>
      }
    </table>
  `
})
export class EboekStaalComponent {
  @Input() stalen: any[];
}
